import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Department } from '../models/department';
import { User } from '../models/user';

@Injectable()
export class DepartmentDataSource {
    constructor(private http: HttpClient) {}

    getDepartments(): Observable<Department[]> {
        return this.http.post<any>(`${environment.apiUrl}/departamentos/user`,{})
            .pipe(map(response => {
                return response;
            }))
    }

    getAllDeparments(filter: string): Observable<Department[]> {
        let url = `${environment.apiUrl}/departamentos?count=1`;
        if(filter != '') url += `&filter=${filter}`
        return this.http.get<any>(url).pipe(map(response => {return response;}));
    }

    getDepartmentById(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/departamento/${id}`).pipe(map(response=>{return response;}));
    }

    getUserDepartments(): Observable<Department[]> {
        return this.http.get<any>(`${environment.apiUrl}/departamentos/user`).pipe(map(response=> {return response;}));
    }

    createDepartment(deparment: Department, managers: User[]) {
        return this.http.post<any>(`${environment.apiUrl}/departamentos`, {
            nombre_depto: deparment.nombre,
            color: deparment.color
        }).pipe(map(response=> {return response;}));
    }

    getUsersInDeparment(id: number): Observable<User[]> {
        return this.http.get<any>(`${environment.apiUrl}/departamento/${id}/users`).pipe(map(response => {return response;}));
    }

    addManagerToDepartment(id_depto: number, id_user: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/departamentos/user/${id_user}`, {
            id_depto: id_depto,
            permiso: 7
        }).pipe(map(response => {return response;}));
    }

    deleteDepartment(id): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/departamento/${id}`).pipe(map(response => {return response;}));
    }

    removeManagerOfDeparment(id_depto, id_user, permiso): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/departamentos/user/${id_user}`, {
            permiso: permiso,
            id_depto: id_depto
        }).pipe(map(response => {return response;}));
    }

    editDepartment(department: Department): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/departamento/${department.id}`, {
            nombre: department.nombre,
            color: department.color
        }).pipe(map(response => {return response;}));
    }

    addUserToDepartment(id_depto: number, id_user: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/departamentos/user/${id_user}`, {
            id_depto: id_depto,
            permiso: 3
        }).pipe(map(response => {return response;}));
    }

    removeUserOfDepartment(id_depto: number, id_user: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/departamentos/user/${id_user}?id_depto=${id_depto}`)
        .pipe(map(response => {return response;}));
    }

    getDepartmentStats(id_depto: number, fechade, fechahasta, usercreator: number, proyecto: number): Observable<any> {
        let url = `${environment.apiUrl}/reporte/departamento/${id_depto}?`;
        if(fechade) url += "fechade="+fechade+"&";
        if(fechahasta) url += "fechahasta="+fechahasta+"&";
        if(usercreator != -1) url += "userto="+usercreator+"&";
        if(proyecto != -1) url+= "proyecto="+proyecto;
        return this.http.get<any>(url).pipe(map(response =>{return response;}));
    }
}