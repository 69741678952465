import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommitmentDataSource } from "../datasources/commitment.datasource";
import { CommCreation } from "../models/commCreation";
import { Score } from "../models/score";

@Injectable()
export class CommitmentRepository {
    constructor(private commitmentDataSource: CommitmentDataSource) {}

    getHomeReport(): Observable<any> {
        return this.commitmentDataSource.getHomeReport();
    }

    private twoDigits(d) {
        if(d < 10) return '0'+d;
        else return d;
    }

    getToCommitments(days, fechade, fechahasta, type, user, proy): Observable<any> {
        let fechahastain = new Date();
        fechahastain.setHours(23,59,59,0);
        let fechadein = new Date();
        fechadein.setHours(0,0,0,0);
        if(days == -1 ){
            fechadein = fechade;
            fechahastain = fechahasta;
        } else {
            if(days > 30) {
                fechadein = new Date(fechadein.getFullYear(), 0, 1);
                fechadein.setHours(0,0,0,0);
            } else {
                if(days != 1) fechadein.setDate(fechadein.getDate() - days);
            }
        }
        let stringde = fechadein.getFullYear()+'-'+this.twoDigits(fechadein.getMonth()+1)+'-'+this.twoDigits(fechadein.getDate());
        stringde += ' '+this.twoDigits(fechadein.getHours())+':'+this.twoDigits(fechadein.getMinutes())+':'+this.twoDigits(fechadein.getSeconds());
        let stringhasta =fechahastain.getFullYear()+'-'+this.twoDigits(fechahastain.getMonth()+1)+'-'+this.twoDigits(fechahastain.getDate());
        stringhasta += ' '+this.twoDigits(fechahastain.getHours())+':'+this.twoDigits(fechahastain.getMinutes())+':'+this.twoDigits(fechahastain.getSeconds());
        return this.commitmentDataSource.getToCommitments(type, stringde, stringhasta,user, proy);
    }

    getSentCommitments(days, fechade, fechahasta, type, user, proy): Observable<any> {
        let fechahastain = new Date();
        fechahastain.setHours(23,59,59,0);
        let fechadein = new Date();
        fechadein.setHours(0,0,0,0);
        if(days == -1 ){
            fechadein = fechade;
            fechahastain = fechahasta;
        } else {
            if(days > 30) {
                fechadein = new Date(fechadein.getFullYear(), 0, 1);
                fechadein.setHours(0,0,0,0);
            } else {
                if(days != 1) fechadein.setDate(fechadein.getDate() - days);
            }
        }
        let stringde = fechadein.getFullYear()+'-'+this.twoDigits(fechadein.getMonth()+1)+'-'+this.twoDigits(fechadein.getDate());
        stringde += ' '+this.twoDigits(fechadein.getHours())+':'+this.twoDigits(fechadein.getMinutes())+':'+this.twoDigits(fechadein.getSeconds());
        let stringhasta =fechahastain.getFullYear()+'-'+this.twoDigits(fechahastain.getMonth()+1)+'-'+this.twoDigits(fechahastain.getDate());
        stringhasta += ' '+this.twoDigits(fechahastain.getHours())+':'+this.twoDigits(fechahastain.getMinutes())+':'+this.twoDigits(fechahastain.getSeconds());
        return this.commitmentDataSource.getSentCommitments(type, stringde, stringhasta,user, proy);
    }

    newCommitment(comm: CommCreation, date): Observable<any> {
        let utc = new Date(date);
        let utcstr = utc.getUTCFullYear() +'-' + this.twoDigits(utc.getUTCMonth()+1) + '-' + this.twoDigits(utc.getUTCDate());
        utcstr += ' ' + this.twoDigits(utc.getUTCHours()) +':'+this.twoDigits(utc.getUTCMinutes()) + ':00';
        return this.commitmentDataSource.newCommitment(comm, date, utcstr);
    }

    getActivityFeed():Observable<any> {
        return this.commitmentDataSource.getActivityFeed();
    }

    addCommentToCommitment(id_comm: number, coment: string): Observable<any> {
        return this.commitmentDataSource.addCommentToCommitment(id_comm, coment);
    }

    getCommitmentComments(id_commit: number): Observable<any> {
        return this.commitmentDataSource.getCommitmentComments(id_commit);
    }

    addFileToCommitment(id_comm: number, archivo): Observable<any> {
        return this.commitmentDataSource.addFileToCommitment(id_comm, archivo);
    }

    getCommitmentFiles(id_comm:number):Observable<any> {
        return this.commitmentDataSource.getCommitmentFiles(id_comm);
    }

    cancelCommitment(id_comm: number, reason: string): Observable<any> {
        return this.commitmentDataSource.cancelCommitment(id_comm, reason);
    }

    acceptCommitment(id_comm: number, year, month, day, hour, minutes): Observable<any> {
        let str = year+'-'+this.twoDigits(month)+'-'+this.twoDigits(day)+' '+this.twoDigits(hour)+':'+this.twoDigits(minutes)+':00';
        return this.commitmentDataSource.acceptCommitment(id_comm, str);
    }

    sendCommitmentToReview(id_comm: number): Observable<any> {
        return this.commitmentDataSource.sendCommitmentToReview(id_comm);
    }

    getCommitment(id_comm: number): Observable<any> {
        return this.commitmentDataSource.getCommitment(id_comm);
    }

    getReasons(id_comm: number): Observable<any> {
        return this.commitmentDataSource.getReasons(id_comm);
    }

    qualifyCommitment(id_comm: number,qualify: Score): Observable<any> {
        return this.commitmentDataSource.qualifyCommitment(id_comm, qualify);
    }

    addDelay(id_comm: number, year, month, day, hour, minutes, reason): Observable<any> {
        let str = year+'-'+this.twoDigits(month)+'-'+this.twoDigits(day)+' '+this.twoDigits(hour)+':'+this.twoDigits(minutes)+':00';
        return this.commitmentDataSource.addDelay(id_comm, str, reason);
    }

    getCommitmentDelays(id_comm):Observable<any> {
        return this.commitmentDataSource.getCommitmentDelays(id_comm);
    }

    reasignCommitment(id_comm: number, user_id: number, year, month, day, hour, minutes):Observable<any> {
        let str = year+'-'+this.twoDigits(month)+'-'+this.twoDigits(day)+' '+this.twoDigits(hour)+':'+this.twoDigits(minutes)+':00';
        return this.commitmentDataSource.reasignCommitment(id_comm, user_id, str);
    }

    searchCommitments(like: string):Observable<any> {
        return this.commitmentDataSource.searchCommitments(like);
    }

    private getToday() {
        let date = new Date();
        let str_date = date.getFullYear() + '-' + this.twoDigits(date.getMonth()+1) + '-' + this.twoDigits(date.getDate());
        return str_date;
    }

    private getLast3Months() {
        let date = new Date();
        date.setMonth(date.getMonth()-3);
        let str_date = date.getFullYear() + '-' + this.twoDigits(date.getMonth()+1) + '-' + this.twoDigits(date.getDate());
        return str_date;
    }
    
    getCommitmentsWithTag(id_tag: number, filter: string): Observable<any> {
        return this.commitmentDataSource.getCommitmentsWithTag(id_tag, this.getLast3Months(), this.getToday(), filter);
    }

    getCommitmentsByDepartment(filter: string): Observable<any> {
        return this.commitmentDataSource.getCommitmentsByDepartment(this.getLast3Months(), this.getToday(), filter);
    }

    getCanceledCommitments(title, tag_id, page):Observable<any> {
        return this.commitmentDataSource.getCanceledCommitments(this.getLast3Months(), this.getToday(), title, tag_id, page);
    }

}