import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MeetingDataSource } from "../datasources/meeting.datasource";

@Injectable()
export class MeetingRepository {
    constructor(
        private datasource: MeetingDataSource
    ){}

    getMacrotaskMeetings(id_macro: number): Observable<any> {
        return this.datasource.getMacrotaskMeetings(id_macro);
    }

    private twoDigit(num) {
        if(num < 10) return '0'+num;
        else return num;
    }

    createMeeting(fecha: Date, id_macro: number): Observable<any> {
        let datestring = fecha.getFullYear() + '-' +this.twoDigit(fecha.getMonth()+1) + '-'+this.twoDigit(fecha.getDate());
        datestring += ' '+fecha.getHours()+':'+fecha.getMinutes();
        return this.datasource.createMeeting(datestring, id_macro)
    }

    deleteMeeting(id_meet: number): Observable<any> {
        return this.datasource.deleteMeeting(id_meet);
    }
}