import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class MacrotaskDataSource {
    constructor(
        private http: HttpClient
    ){}

    getMacrotasksOfProject(id_project): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/proyectos/${id_project}/macrotareas`)
            .pipe(map(response => {return response;}))
    }

    createMacrotask(nombre: string, desc: string, fecha_entrega: string, id_project: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/proyectos/${id_project}/macrotareas`, {
            nombre: nombre,
            descripcion: desc,
            fecha_entrega: fecha_entrega
        }).pipe(map(response => {return response;}));
    }

    editMacrotask(id_macro: number, nombre: string, desc: string, fecha_entrega: string):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/macrotarea/${id_macro}`, {
            nombre: nombre,
            descripcion: desc,
            fecha_entrega: fecha_entrega
        }).pipe(map(response => {return response;}));
    }

    addUserToMacrotask(id_macro: number, id_user: number): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/macrotarea/${id_macro}`, {
            user_id: id_user
        }).pipe(map(response=>{return response;}));
    }

    removeUserOfMacrotask(id_macro: number, id_user: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/macrotarea/${id_macro}?id_user=${id_user}`)
            .pipe(map(response => {return response;}));
    }

    addDepartmentToMacrotask(id_macro: number, id_dep: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/macrotarea/${id_macro}/departamento`, {
            dep_id: id_dep
        }).pipe(map(response => {return response;}));
    }

    removeDepartmentOfMacrotask(id_macro: number, id_dep: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/macrotarea/${id_macro}/departamento?dep_id=${id_dep}`).pipe(map(response => {return response;}));
    }

    addTagToMacrotask(id_macro: number, id_tag: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/macrotarea/${id_macro}/tag`, {
            tag_id: id_tag
        }).pipe(map(response => {return response;}));
    }

    removeTagToMacrotask(id_macro: number, id_tag: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/macrotarea/${id_macro}/tag?tag_id=${id_tag}`).pipe(map(response => {return response;}));
    }

    deleteMacrotask(id_macro: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/macrotarea/${id_macro}`).pipe(map(response=>{return response;}));
    }

    getMacrotaskCommitments(id_macro: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/macrotarea/${id_macro}/compromisos`).pipe(map(response=>{return response;}));
    }

    getMacrotaskFiles(id_macro: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/macrotarea/${id_macro}/files`).pipe(map(response=>{return response;}));
    }
}