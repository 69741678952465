import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { CompanyDataSource } from '../datasources/company.datasource';
import { Company } from '../models/company';
import { CompanyBilling } from "../models/companyBilling";
import { Department } from '../models/department';
import { User } from '../models/user';

@Injectable()
export class CompanyRepository {
    constructor(
        private dataSource: CompanyDataSource
    ) {}

    createCompany( company: Company, department: Department, user: User, password, token, birth: Date, aniv: Date): Observable<any> {
        return this.dataSource.createCompany(company, department, user, password, token, birth, aniv);
    }

    checkCompanyNameAvailable( name: string ): Observable<any> {
        return this.dataSource.checkCompanyNameAvailable(name);
    }

    getCompanyResume(): Observable<any> {
        return this.dataSource.getCompanyResume();
    }

    getStates():Observable<any> {
        return this.dataSource.getStates();
    }

    getCities(state_id: number):Observable<any> {
        return this.dataSource.getCities(state_id);
    }

    changeLicensesNumber(num: number):Observable<any> {
        return this.dataSource.changeLicensesNumber(num);
    }

    getBilling():Observable<any> {
        return this.dataSource.getBilling();
    }

    updateBilling(bill: CompanyBilling):Observable<any> {
        return this.dataSource.updateBilling(bill);
    }

    getSubscription():Observable<any> {
        return this.dataSource.getSubscription();
    }

    getPaymentHistory():Observable<any> {
        return this.dataSource.getPaymentHistory();
    }

    generateSubscription(nombre: string, email: string, phone: string, token: string):Observable<any> {
        return this.dataSource.generateSubscription(nombre, email, phone, token);
    }

    deleteSubscription():Observable<any> {
        return this.dataSource.deleteSubscription();
    }

    payInvoice(inv_id, cliente, email, phone, token): Observable<any> {
        return this.dataSource.payInvoice(inv_id, cliente, email, phone, token);
    }

    legalInvoice(inv_id): Observable<any> {
        return this.dataSource.legalInvoice(inv_id);
    }

    updateCheckInTime(checkin_time: string): Observable<any> {
        return this.dataSource.updateCheckInTime(checkin_time);
    }

    getCheckInTime(): Observable<any> {
        return this.dataSource.getCheckInTime();
    }
}