import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class OutdepartamentDataSource {
    constructor(
        private http: HttpClient
    ){}

    getRequests(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/departamentos/request`).pipe(map(response => {return response;}));
    }

    newRequest(depto_id:number ): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/departamentos/request`,{
            depto_id: depto_id
        }).pipe(map(response => { return response; }));
    }

    acceptRequest(req_id:number ): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/departamentos/request/${req_id}`, {}).pipe(map(response => {return response;}));
    }

    denyRequest(req_id:number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/departamentos/request/${req_id}`, {}).pipe(map(response => {return response;}));
    }
}