import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FileDataSource } from "../datasources/files.datasource";

@Injectable()
export class FileRepository {
    constructor(
        private datasource: FileDataSource
    ){}

    getCommitmentFiles(comm_id: number): Observable<any> {
        return this.datasource.getCommitmentFiles(comm_id);
    }

    getSpace(): Observable<any> {
        return this.datasource.getSpace();
    }

    deleteFile(files: number[]): Observable<any> {
        return this.datasource.deleteFile(files);
    }
}