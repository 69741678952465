import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class DailyReportDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getSentReports(year: number, month: number, day: number, people): Observable<any> {
        let url = `${environment.apiUrl}/reportediario?tipo=enviados`
        url += `&year=${year}&month=${month}&day=${day}`;
        if(people.length > 0) for (const person of people) url+=`&user_to=${person}`;
        return this.http.get<any>(url).pipe(map(response => {return response}));
    }

    getReceivedReports(year: number, month: number, day: number, people): Observable<any> {
        let url = `${environment.apiUrl}/reportediario?`;
        url += `year=${year}&month=${month}&day=${day}`;
        if(people.length > 0) for (const person of people) url+=`&user_to=${person}`;
        return this.http.get<any>(url).pipe(map(response => {return response}));
    }

    sendReport(texto: string, fecha_user: string, user_id): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/reportediario`, {
            texto: texto,
            fecha_user: fecha_user,
            'user_id[]': user_id 
        }).pipe(map(response => { return response; }));
    }

    addFileToReport(report_id: number, file): Observable<any> {
        var fd = new FormData();
        fd.append('archivo', file)
        return this.http.post<any>(`${environment.apiUrl}/files/reportediario/${report_id}`, fd).pipe(map(response=>{return response;}));
    }

    getReportFiles(report_id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/files/reportediario/${report_id}`).pipe(map(response =>{return response}));
    }

    setReportAsSeen(report_id): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/reportediario/${report_id}`, {}).pipe(map(response => {return response;}));
    }
}