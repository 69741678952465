import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PartnerData } from "../models/partnerdata";

@Injectable()
export class PartnerDataSource {
    constructor( private _http: HttpClient) {}

    getPartnerData(): Observable<any> {
        return this._http.get<any>(`${environment.apiUrl}/partnerdata`).pipe(map(response=>{return response;}));
    }

    updatePartnerDate(data: PartnerData): Observable<any> {
        return this._http.put<any>(`${environment.apiUrl}/partnerdata`, {
            nombre: data.nombre,
            apellido_uno: data.apellido_uno,
            apellido_dos: data.apellido_dos,
            clabe: data.clabe,
            banco: data.banco,
            telefono: data.telefono,
            no_tarjeta: data.no_tarjeta
        }).pipe(map(response=>{return response;}));
    }

    getPartnerCode(): Observable<any> {
        return this._http.get<any>(`${environment.apiUrl}/codeGenerate`).pipe(map(response=>{return response;}));
    }
}