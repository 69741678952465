import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from 'rxjs/operators';
import { Commitment } from "../models/commitment";
import { CommCreation } from "../models/commCreation";
import { Score } from "../models/score";

@Injectable()
export class CommitmentDataSource {
    constructor(private http: HttpClient) {}

    getHomeReport(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/reporte/home`).pipe(map(response => {return response}));
    }

    getToCommitments(type: string, fechade: string, fechahasta: string, user: number, proyecto: number ): Observable<Commitment[]> {
        let url = `${environment.apiUrl}/estadisiticas/compromisos/usuario?`;
        url += 'fechade='+fechade+'&fechahasta='+fechahasta;
        if(type != 'all') url+= '&tipo='+type;
        if(user != -1) url += '&from_user='+user;
        if(proyecto != -1) url += '&proyecto='+proyecto;
        return this.http.get<any>(url).pipe(map(response => {return response;}));
    }

    getSentCommitments(type: string, fechade: string, fechahasta: string, user: number, proyecto: number): Observable<Commitment[]> {
        let url = `${environment.apiUrl}/estadisiticas/compromisos/otros?`;
        url += 'fechade='+fechade+'&fechahasta='+fechahasta;
        if(type != 'all') url+= '&tipo='+type;
        if(user != -1) url += '&to_user='+user;
        if(proyecto != -1) url += '&proyecto='+proyecto;
        return this.http.get<any>(url).pipe(map(response => {return response;}));
    }

    newCommitment(comm: CommCreation, fecha: string, utc): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/compromiso`, {
            user_to: comm.user_to,
            depto_to: comm.depto_to,
            no_nego: comm.no_nego,
            num_comp: comm.num_comp,
            proyecto: comm.proyecto,
            fecha_tentativa: fecha,
            fecha_tentativa_UTC: utc,
            title: comm.title,
            contenido: comm.contenido,
            privado: 0,
            id_macrotarea: comm.id_macrotarea
        }).pipe(map(response => {return response;}));
    }

    getActivityFeed():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/activityfeed`).pipe(map(response=>{return response;}));
    }

    addCommentToCommitment(id_comm: number, coment: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/comentarios/compromiso/${id_comm}`,{
            comentario: coment
        }).pipe(map(response => { return response; }));
    }

    getCommitmentComments(id_commit: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/comentarios/compromiso/${id_commit}`).pipe(map(response=>{return response;}));
    }

    addFileToCommitment(id_comm: number, archivo): Observable<any> {
        var fd = new FormData();
        fd.append('archivo', archivo);
        fd.append('privado', '0');
        return this.http.post<any>(`${environment.apiUrl}/files/compromiso/${id_comm}`, fd).pipe(map(response=>{return response;}));
    }

    getCommitmentFiles(id_comm:number):Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/files/compromiso/${id_comm}`).pipe(map(response=>{return response;}));
    }

    cancelCommitment(id_comm: number, reason: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/compromiso/${id_comm}?razon=${reason}`).pipe(map(response=>{return response;}));
    }

    acceptCommitment(id_comm: number, fecha_real: string): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/compromiso/${id_comm}`,{
            fecha_real: fecha_real
        }).pipe(map(response => {return response;}));
    }

    sendCommitmentToReview(id_comm: number): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/calificar/compromiso/${id_comm}`,{}).pipe(map(response=>{return response;}));
    }

    getCommitment(id_comm: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/compromiso/${id_comm}`).pipe(map(response => {return response;}));
    }

    getReasons(id_comm: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/reasons?id_comm=${id_comm}`).pipe(map(response=>{return response;}));
    }

    qualifyCommitment(id_comm: number,qualify: Score): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/calificar/compromiso/${id_comm}`, {
            razon: qualify.reason_id,
            calificacion: qualify.calificacion_id,
            comentario: qualify.comentario,
            stars: qualify.stars
        }).pipe(map(response=>{return response;}));
    }

    addDelay(id_comm: number, date: string, reason: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/delay/compromiso/${id_comm}`, {
            razon: reason,
            fecha_nueva: date
        }).pipe(map(response=>{return response;}));
    }

    getCommitmentDelays(id_comm):Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/delay/compromiso/${id_comm}`).pipe(map(response=>{return response;}));
    }

    reasignCommitment(id_comm: number, user_id: number, fecha: string):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/reasignar/compromiso/${id_comm}`,{
            user_id: user_id,
            fecha_nueva: fecha
        }).pipe(map(response => {return response;}));
    }

    searchCommitments(like: string):Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/buscar/compromisos?like=${like}`).pipe(map(response=>{return response;}));
    }

    getCommitmentsWithTag(id_tag: number, ini_date: string, end_date: string, filter: string): Observable<any> {
        let url = `${environment.apiUrl}/tagsub/${id_tag}?ini_date=${ini_date}&end_date=${end_date}`;
        if(filter != '') url += `&title=${filter}`;
        return this.http.get<any>(url).pipe(map(response=>{return response;}));
    }

    getCommitmentsByDepartment(ini_date, end_date, filter: string): Observable<any> {
        let url = `${environment.apiUrl}/compromisos/by_department?ini_date=${ini_date}&end_date=${end_date}`;
        if(filter != '') url += `&title=${filter}`;
        return this.http.get<any>(url).pipe(map(response=>{return response;}));
    }

    getCanceledCommitments(ini_date, end_date, title, tag_id, page):Observable<any> {
        let url = `${environment.apiUrl}/compromisos/archivados?`;
        url += `page=${page}&items_per_page=6`;
        url += `&ini_date=${ini_date}&end_date=${end_date}&title=${title}`;
        if(tag_id != -1) url+=`&tag_id=${tag_id}`;
        return this.http.get<any>(url).pipe(map(response=>{return response;}));
    }
}