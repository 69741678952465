import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OutdepartamentDataSource } from "../datasources/outdepartment.datasource";

@Injectable()
export class OutdepartmentRepository {
    constructor(
        private datasource: OutdepartamentDataSource
    ){}

    getRequests(): Observable<any> {
        return this.datasource.getRequests();
    }

    newRequest(depto_id:number ): Observable<any> {
        return this.datasource.newRequest(depto_id);
    }

    acceptRequest(req_id:number ): Observable<any> {
        return this.datasource.acceptRequest(req_id);
    }

    denyRequest(req_id:number ): Observable<any> {
        return this.datasource.denyRequest(req_id);
    }
}