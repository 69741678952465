import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProjectDataSource } from "../datasources/project.datasource";

@Injectable()
export class ProjectRepository {
    constructor(
        private projectDatasource: ProjectDataSource
    ) {}

    getProjects(filter: string, creator: number): Observable<any> {
        return this.projectDatasource.getProjects(filter, creator);
    }

    getAllProjects(filter: string, creator: number): Observable<any> {
        return this.projectDatasource.getAllProjects(filter, creator);
    }

    private twoDigits(num) {
        if(num > 10) return num;
        else return '0'+num;
    } 

    addProject(name: string, color: string, file, inicio: Date, fin: Date): Observable<any> {
        let initdate = inicio.getUTCFullYear()+'-'+this.twoDigits(inicio.getUTCMonth()+1)+'-'+this.twoDigits(inicio.getUTCDate());
        let enddate = fin.getUTCFullYear()+'-'+this.twoDigits(fin.getUTCMonth()+1)+'-'+this.twoDigits(fin.getUTCDate());
        return this.projectDatasource.addProject(name, color, file, initdate, enddate);
    }

    editProject(id:number, name: string, color: string, file, inicio: Date, fin: Date): Observable<any> {
        let initdate = inicio.getUTCFullYear()+'-'+this.twoDigits(inicio.getUTCMonth()+1)+'-'+this.twoDigits(inicio.getUTCDate());
        let enddate = fin.getUTCFullYear()+'-'+this.twoDigits(fin.getUTCMonth()+1)+'-'+this.twoDigits(fin.getUTCDate());
        return this.projectDatasource.editProject(id, name, color, file, initdate, enddate);
    }

    addPersonToProject(id_proj: number, id_user: number): Observable<any> {
        return this.projectDatasource.addPersonToProject(id_proj, id_user);
    }

    removePersonOfProject(id_proj: number, id_user: number): Observable<any> {
        return this.projectDatasource.removePersonOfProject(id_proj, id_user);
    }

    getProjectInfo(id_proj: number):Observable<any> {
        return this.projectDatasource.getProjectInfo(id_proj);
    }

    deactivateProject(id_proj: number):Observable<any> {
        return this.projectDatasource.deactivateProject(id_proj);
    }

    reactivateProject(id_proj: number):Observable<any> {
        return this.projectDatasource.reactivateProject(id_proj);
    }

    getProjectCommitmentsCount(id_proj: number): Observable<any> {
        return this.projectDatasource.getProjectCommitmentsCount(id_proj);
    }

    getProjectMacrotasks(id_proj: number): Observable<any> {
        return this.projectDatasource.getProjectMacrotasks(id_proj);
    }

    getMenuActivities():Observable<any> {
        return this.projectDatasource.getMenuActivities();
    }

    getProjectCommitmentsStats(days, fechade, fechahasta, userBy, userC, proy): Observable<any> {
        let fechahastain = new Date();
        fechahastain.setHours(23,59,59,0);
        let fechadein = new Date();
        fechadein.setHours(0,0,0,0);
        if(days == -1 ){
            fechadein = fechade;
            fechahastain = fechahasta;
        } else {
            if(days > 30) {
                fechadein = new Date(fechadein.getFullYear(), 0, 1);
                fechadein.setHours(0,0,0,0);
            } else {
                if(days != 1) fechadein.setDate(fechadein.getDate() - days);
            }
        }
        return this.projectDatasource.getProjectCommitmentsStats(fechadein, fechahastain, userBy, userC, proy);
    }
}