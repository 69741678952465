import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class NotificationsDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getNotReadedNotifications(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/notificaciones`).pipe(map(response => { return response;}));
    }

    getAllNotifications(from_id: number): Observable<any> {
        let url = `${environment.apiUrl}/notificaciones?all=si`;
        if(from_id != 0) url+=`&from_id=${from_id}`;
        return this.http.get<any>(url).pipe(map(response => { return response;}));
    }

    readNotifications(ids: number[]):Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/notificaciones`, {
            notif_id: ids
        }).pipe(map(response => { return response;}));
    }

    getUserPreferences():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/user/preferences`).pipe(map(response => {return response;}));
    }

    saveUserPreference(preference: string, value):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/user/preferences`, {
            preference: preference,
            value: value
        }).pipe(map(response => {return response;}));
    }

    getNotificationById(id_not: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/notificacion/${id_not}`).pipe(map(response=>{return response}));
    }
}