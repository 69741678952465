import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationsDataSource } from "../datasources/notifications.datasource";

@Injectable()
export class NotificationsRepository {
    constructor(
        private datasource: NotificationsDataSource
    ) {}

    getNotReadedNotifications(): Observable<any> {
        return this.datasource.getNotReadedNotifications();
    }

    getAllNotifications(from_id: number): Observable<any> {
        return this.datasource.getAllNotifications(from_id);
    }

    readNotifications(ids: number[]):Observable<any> {
        return this.datasource.readNotifications(ids);
    }

    getUserPreferences():Observable<any> {
        return this.datasource.getUserPreferences();
    }

    saveUserPreference(preference: string, value):Observable<any> {
        return this.datasource.saveUserPreference(preference, value);
    }

    getNotificationById(id_not: number): Observable<any> {
        return this.datasource.getNotificationById(id_not);
    }
}