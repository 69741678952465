import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.auth.token;

        if(token) {
            if(!req.headers.get('Authorization')) {
                let authdata = btoa(token +':notused');
                req = req.clone({
                    setHeaders: {
                        Authorization: `Basic ${authdata}`
                    }
                });
            }
        }

        return next.handle(req).pipe(
            tap( event => {
                if ( event instanceof HttpResponse ){
                    if (event.headers.has('Authorization')){
                        const newToken = event.headers.get('Authorization').split(" ")[1];
                        this.auth.token = newToken;
                    }
                }
            }, error => {
                if (error instanceof HttpErrorResponse){
                    if(error.status === 401){
                        this.auth.clear();
                        this.router.navigate(['/login']);
                    }else{
                        return throwError(error);
                    }
                }else{
                    return throwError(error);
                }
            }
        ));
    }
}