import { Injectable } from "@angular/core";

@Injectable() 
export class DragService {
    private zoneIDs: Array<string>;
    private availableZones: any = {};

    public startDrag(zoneIDs: Array<string>) {
        this.zoneIDs = zoneIDs;
        this.highLightAvailableZones();
    }

    public accepts(zoneID: string): boolean {
        return (this.zoneIDs.indexOf(zoneID) > -1);
    }

    public removeHighLightedAvailableZones(): void {
        this.zoneIDs.forEach((zone: string)=> {
            this.availableZones[zone].end();
        });
    }

    public addAvailableZone(zoneID: string, obj: {begin: Function, end: Function}): void {
        this.availableZones[zoneID] = obj;
    }

    public removeAvailableZone(zoneID: string): void {
        delete this.availableZones[zoneID];
    }

    private highLightAvailableZones(): void {
        this.zoneIDs.forEach((zone: string) => {
            this.availableZones[zone].begin();
        });
    }
}