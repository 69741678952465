import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class FileDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getCommitmentFiles(comm_id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/files/compromiso/${comm_id}`).pipe(map(response => {return response;}));
    }

    getSpace(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/files/admin`).pipe(map(response => { return response;}));
    }

    deleteFile(files: number[]): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/files/admin?files=${files}`).pipe(map(response => {return response;}));
    }
}