import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { DepartmentDataSource } from '../datasources/department.datasource';
import { Department } from "../models/department";
import { User } from "../models/user";

@Injectable()
export class DepartmentRepository {
    constructor(private departmentDataSource: DepartmentDataSource) {}

    getDepartments(): Observable<any> {
        return this.departmentDataSource.getDepartments();
    }

    getAllDepartments(filter: string): Observable<any> {
        return this.departmentDataSource.getAllDeparments(filter);
    }

    getDepartmentById(id: number): Observable<any> {
        return this.departmentDataSource.getDepartmentById(id);
    }

    getUserDepartments(): Observable<any> {
        return this.departmentDataSource.getUserDepartments();
    }

    createDepartment(deparment: Department, managers: User[]): Observable<any> {
        return this.departmentDataSource.createDepartment(deparment, managers);
    }

    getUsersInDepartment(id: number): Observable<any> {
        return this.departmentDataSource.getUsersInDeparment(id);
    }

    addManagerToDepartment(id_depto: number, id_user: number): Observable<any> {
        return this.departmentDataSource.addManagerToDepartment(id_depto, id_user);
    }

    deleteDepartment(id): Observable<any> {
        return this.departmentDataSource.deleteDepartment(id);
    }

    removeManagerOfDeparment(id_depto, id_user, permiso): Observable<any> {
        return this.departmentDataSource.removeManagerOfDeparment(id_depto, id_user, permiso);
    }

    editDepartment(department: Department): Observable<any> {
        return this.departmentDataSource.editDepartment(department);
    }

    addUserToDepartment(id_depto: number, id_user: number): Observable<any> {
        return this.departmentDataSource.addUserToDepartment(id_depto, id_user);
    }

    removeUserOfDepartment(id_depto: number, id_user: number): Observable<any> {
        return this.departmentDataSource.removeUserOfDepartment(id_depto, id_user);
    }

    getDepartmentStats(id_depto: number, days, fechade: Date, fechahasta: Date, usercreator: number, proyecto: number): Observable<any> {
        let fechadein =  new Date();
        let fechahastain = new Date();
        if (days == -1) {
            fechadein = fechade;
            fechahastain = fechahasta;
        } else {
            if(days > 30) fechadein = new Date(fechadein.getFullYear(), 0, 1);
            else if (days != 1) fechadein.setDate(fechadein.getDate() - days);
        }
        let fechadestr = fechadein.getFullYear()+'-'+this.twoDigits((fechadein.getMonth()+1))+'-'+this.twoDigits(fechadein.getDate());
        fechadestr += ' 00:00:00';
        let fechahastastr = fechahastain.getFullYear()+'-'+this.twoDigits(fechahastain.getMonth()+1)+'-'+this.twoDigits(fechahastain.getDate());
        fechahastastr += ' 23:59:59';
        return this.departmentDataSource.getDepartmentStats(id_depto, fechadestr, fechahastastr, usercreator, proyecto);
    }

    private twoDigits(num) {
        if(num > 9) return num;
        else return '0'+num;
    }
}