import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TagDataSource } from "../datasources/tag.datasource";

@Injectable()
export class TagRepository {
    constructor(
        private datasource: TagDataSource
    ) {}

    getTags(filter: string):Observable <any> {
        return this.datasource.getTags(filter);
    }

    createTag(nombre: string, color: string): Observable<any> {
        return this.datasource.createTag(nombre, color);
    }

    editTag(id: number, nombre: string, color: string): Observable<any> {
        return this.datasource.editTag(id, nombre, color);
    }

    deleteTag(id: number):Observable<any> {
        return this.datasource.deleteTag(id);
    }

    assignTagsToCommitments(tags: number[], comps: number[]) {
        return this.datasource.assignTagsToCommitments(tags, comps);
    }
}