import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Ticker } from "../models/ticker";

@Injectable()
export class TickerDataSource {
    constructor(private http: HttpClient) {}

    createNew(ticker: Ticker, depto_to: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/admin/ticker`,{
            text: ticker.text,
            titulo: ticker.titulo,
            expires: ticker.fecha_expires,
            id_depto: depto_to,
            template_id: ticker.template_id
        }).pipe(map(response => {return response;}))
    } 

    getAdminNews():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/admin/ticker`).pipe(map(response => {return response;}));
    }

    deleteNew(id_ticker: number):Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/admin/ticker`, {
            delete: id_ticker
        }).pipe(map(response => { return response;}));
    }

    getNews():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/ticker`).pipe(map(response => { return response;}));
    }
}