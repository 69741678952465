 import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "../models/user";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthService {
    currentToken: string;
    currentUser: User;

    constructor(private http: HttpClient, public router: Router) {
        const token: string = <string>localStorage.getItem('token');
        this.currentToken = token;
    }

    login(email: string, password: string) {
        const headers = new HttpHeaders({
            'Authorization': 'Basic '+ btoa(email+':'+password)
        });
        return this.http.get<any>(`${environment.apiUrl}/token`, {headers: headers})
            .pipe(map(user => {
                localStorage.setItem('user', JSON.stringify(user));
                return user;
            }));
    }

    emailAuth(email: string) {
        return this.http.put<any>(`${environment.apiUrl}/check/email`, 
            { email: email})
            .pipe(map(response=> {
                return response;
            }));
    }

    emailAuthWithToken(email: string, token: string) {
        return this.http.request<any>('delete', `${environment.apiUrl}/check/email`, 
            { body: {
                email: email,
                token: token
            }})
            .pipe(map(response=> {
                return response;
            }));
    }

    getExpiration() {
        let user = this.user;
        if(user) {
            let date = new Date(this.user.expires * 1000);
            return date;
        }
    }
    
    set token(token: string) {
        localStorage.setItem('token', token);
        this.currentToken = token;
    }

    get token(): string {
        const token: string = <string>localStorage.getItem('token');
        return token;
    }

    set user(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
        this.currentUser = user;
    }

    get user(): User {
        const currentUser = JSON.parse( localStorage.getItem('user'));
        return currentUser;
    }

    set currentMacro(macro: number) {
        let mstr = macro.toString();
        localStorage.setItem('currentMacro', mstr);
    }

    get currentMacro(): number {
        const currentMacro = <number><unknown>localStorage.getItem('currentMacro');
        return currentMacro;
    }

    set currentProject(proj: number) {
        let pstr = proj.toString();
        localStorage.setItem('currentProject', pstr);
    }

    get currentProject(): number {
        const currentProject = <number><unknown>localStorage.getItem('currentProject');
        return currentProject;
    }

    set partnerModal(value: boolean) {
        let str = 'no';
        if(value) str = 'yes';
        localStorage.setItem('partnerModal', str);
    }

    get partnerModal(): boolean {
        let value = localStorage.getItem('partnerModal');
        if(value == 'yes') return true;
        else return false;
    }

    clear() {
        localStorage.removeItem('token');
        return true;
    }

    completeClear() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return true;
    }

    isLoggedIn() {
        if(this.token != null) {
            let currentUser = JSON.parse( localStorage.getItem('user'));
            let expires = new Date(currentUser.expires * 1000);
            let now = new Date();
            if(expires > now) return true;
            else return false;
        } 
        else return false;
    }
}
