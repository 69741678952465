import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class TagDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getTags(filter: string): Observable<any> {
        let url = `${environment.apiUrl}/tag`;
        if ( filter != '') url += `?s=${filter}`
        return this.http.get<any>(url).pipe(map(response => {return response;}));
    }

    createTag(nombre: string, color: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/tag`, {
            nombre: nombre,
            color: color
        }).pipe(map(response => {return response;}));
    }

    editTag(id: number, nombre: string, color: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/tag/${id}`, {
            nombre: nombre,
            color: color
        }).pipe(map(response => {return response;}));
    }

    deleteTag(id: number):Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/tag/${id}`).pipe(map(response => { return response; }));
    }

    assignTagsToCommitments(tags: number[], comps: number[]) {
        return this.http.post<any>(`${environment.apiUrl}/tag/compromisos`, {
            tag: tags,
            compromiso_ids: comps
        }).pipe(map(response => {return response;}));
    }
}