import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable()
export class UserDataSource {
    constructor(
        private http: HttpClient
    ) {}

    forgotPassword( email: string ): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/user/forgot`, {
            email: email
        }).pipe(map(response => {
            return response
        }));
    }

    verifyForgotCode( code: string ): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/user/forgot/${code}`)
            .pipe(map(response => {
                return response;
            }));
    }

    changePassword( code: string, password: string, repeat: string ): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/user/forgot/${code}`, {
            new_password: password,
            repeat_password: repeat
        }).pipe(map(response => {
            return response;
        }));
    }

    createUser( user: User, birthday, aniv, pass: string, conf: string, depto: number, type: number ):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users`, {
            username: user.username,
            password: pass,
            password_d: conf,
            dia: birthday.day,
            year: birthday.year,
            mes: (birthday.month+1),
            mes_aniv: (aniv.month+1),
            dia_aniv: aniv.day,
            year_aniv: aniv.year,
            email: user.email,
            nombre_real: user.nombre_real,
            puesto: user.puesto,
            id_depto: depto,
            nivel: type,
            phone: user.phone
        }).pipe(map(response => {
            return response;
        }))
    }

    getUser(id: number):Observable<User> {
        return this.http.get<any>(`${environment.apiUrl}/users/${id}`).pipe(map(response => {return response;}));
    }

    getUserInfo(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users/${id}`, {}).pipe(map(response=> {return response;}));
    }

    getAllUsers(filter: string): Observable<User[]> {
        let url = `${environment.apiUrl}/users?`;
        if(filter != '') url += 's='+filter;
        return this.http.get<any>(url).pipe(map(response => {return response;}));
    }

    getUsersOfCompany(depid: number, projectid: number, filter: string, status: number): Observable<User[]> {
        let url = `${environment.apiUrl}/reporte/usuarios?`;
        if(depid != -1) url+= 'departamento='+depid;
        if(projectid != -1) url += '&proyecto='+projectid;
        if(filter != '') url += '&s='+filter;
        if(status != -1) url += '&status='+status;
        return this.http.get<any>(url)
            .pipe(map(response => {return response;}))
    }

    changeAvatar(file): Observable<any> {
        var fd = new FormData();
        let coords = "100, 100, 200, 200, 100, 100";
        fd.append('avatar', file);
        fd.append('coords', coords);
        return this.http.put<any>(`${environment.apiUrl}/user/profile`, fd)
            .pipe(map(response=>{return response;}));
    }

    changeProfileInfo(user: User): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/user/profile`, {
            nombre: user.nombre_real,
            puesto: user.puesto,
            email: user.email
        }).pipe(map(response=> {return response;}));
    }

    changeProfilePassword(old, newp, repeat): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/user/profile/change_password`, {
            old_password: old,
            new_password: newp,
            repeat_password: repeat
        }).pipe(map(response => {return response;}));
    }

    private twoDigits(d) {
        if(d < 10) return '0'+d;
        else return d;
    }

    getProfileCommitmentsStats(fechade: Date, fechahasta: Date): Observable<any> {
        let stringde = fechade.getFullYear()+'-'+this.twoDigits(fechade.getMonth()+1)+'-'+this.twoDigits(fechade.getDate());
        stringde += ' '+this.twoDigits(fechade.getHours())+':'+this.twoDigits(fechade.getMinutes())+':'+this.twoDigits(fechade.getSeconds())
        let stringhasta = fechahasta.getFullYear()+'-'+this.twoDigits(fechahasta.getMonth()+1)+'-'+this.twoDigits(fechahasta.getDate());
        stringhasta += ' '+this.twoDigits(fechahasta.getHours())+':'+this.twoDigits(fechahasta.getMinutes())+':'+this.twoDigits(fechahasta.getSeconds());
        return this.http.get<any>(`${environment.apiUrl}/estadisticas/usuario?fechade=${stringde}&fechahasta=${stringhasta}`)
            .pipe(map(response => {return response;}));
    }

    getUserCommitmentStats(user_id, fechade: Date, fechahasta: Date, userBy: number, proy: number): Observable<any> {
        let stringde = fechade.getFullYear()+'-'+this.twoDigits(fechade.getMonth()+1)+'-'+this.twoDigits(fechade.getDate());
        stringde += ' '+this.twoDigits(fechade.getHours())+':'+this.twoDigits(fechade.getMinutes())+':'+this.twoDigits(fechade.getSeconds());
        let stringhasta =fechahasta.getFullYear()+'-'+this.twoDigits(fechahasta.getMonth()+1)+'-'+this.twoDigits(fechahasta.getDate());
        stringhasta += ' '+this.twoDigits(fechahasta.getHours())+':'+this.twoDigits(fechahasta.getMinutes())+':'+this.twoDigits(fechahasta.getSeconds());
        let url = `${environment.apiUrl}/reporte/usuario/${user_id}?fechade=${stringde}&fechahasta=${stringhasta}`;
        if(userBy != -1) url += '&usercreator='+userBy;
        if(proy != -1) url +='&proyecto='+proy;
        return this.http.get<any>(url)
            .pipe(map(response => {return response;}));
    }

    updateUser(user: User, birthday, aniv, pass: string, conf: string, type: number): Observable<any> {
        var body = {
            permiso: type,
            email: user.email,
            dia: birthday.day,
            mes: (birthday.month+1),
            year: birthday.year,
            dia_aniv: aniv.day,
            mes_aniv: (aniv.month+1),
            year_aniv: aniv.year
        };
        if(pass != '') {
            body['password'] = pass;
            body['repeat_password'] = conf;
        }
        return this.http.put<any>(`${environment.apiUrl}/users/${user.user_id}`, body)
            .pipe(map(response => {return response;}));
    }

    deactivateUser(user_id: number ): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/users/${user_id}`).pipe(map(response => {return response;}));
    }

    tagUserIntoComment(user_id: number[], com_id: number) :Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/tag/user/compromiso/${com_id}`,{
            id_user: user_id
        }).pipe(map(response => {return response;}));
    }

    getUserSubscriptions(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/tags/subscriptions`).pipe(map(response=>{return response;}));
    }

    subscribeToTag(tag_id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/subscribe/tag/${tag_id}`, {}).pipe(map(response=>{return response;}));
    }

    unsuscribeFromTag(tag_id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/unsubscribe/tag/${tag_id}`, {}).pipe(map(response=>{return response;}));
    }

    getUserTaggedCommitments(ini_date, end_date, filter: string): Observable<any> {
        let url = `${environment.apiUrl}/user/tags?ini_date=${ini_date}&end_date=${end_date}`;
        if(filter != '') url += `&title=${filter}`;
        return this.http.get<any>(url).pipe(map(response => {return response}));
    }

    getBirthdays(): Observable<any> {
        let today = new Date();
        return this.http.get<any>(`${environment.apiUrl}/users/birthdays?mes=${today.getMonth()+1}&year=${today.getFullYear()}`)
            .pipe(map(response => {return response}));
    }

    getHomeWeekStats(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/reporte/home/pending/by_week`).pipe(map(response=>{return response;}));
    }

    getHomeMonthStats(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/reporte/compromisos/completed/by_month`).pipe(map(response=>{return response;}));
    }

    setAsPaymentMaker(user_id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/meta/user/${user_id}`, {
            meta: 2
        }).pipe(map(response => {return response;}));
    }

    removePaymentMaker(user_id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/meta/user/${user_id}?meta=pagos`).pipe(map(response=>{return response;}));
    }

    getPaymentMakers():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/meta/users/pagos`).pipe(map(response=>{return response;}));
    }
}