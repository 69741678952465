import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class MeetingDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getMacrotaskMeetings(id_macro: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/macrotarea/${id_macro}/meeting`).pipe(map(response=>{return response;}));
    }

    createMeeting(fecha: string, id_macro: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/macrotarea/${id_macro}/meeting`, {
            fecha: fecha
        }).pipe(map(response => {return response;}));
    }

    deleteMeeting(id_meet: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/meeting/${id_meet}`).pipe(map(response=>{return response;}));
    }
}