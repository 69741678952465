import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DispersionDataSource } from "../datasources/dispersion.datasource";

@Injectable()
export class DispersionRepository {
    constructor(
        private dispersionDatasource: DispersionDataSource
    ) {}

    getDispersionUsers(filter): Observable<any> {
        return this.dispersionDatasource.getDispersionUsers(filter);
    }

    getRewards(filter): Observable<any> {
        return this.dispersionDatasource.getRewards(filter);
    }

    setPayment(id, data): Observable<any> {
        return this.dispersionDatasource.setPayment(id,data);
    }

}