import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { UserDataSource } from '../datasources/user.datasource';
import { User } from '../models/user';

@Injectable()
export class UserRepository {
    constructor(
        private userDataSource: UserDataSource
    ){}
    
    forgotPassword(email: string): Observable<any> {
        return this.userDataSource.forgotPassword(email);
    }

    verifyForgotCode( code: string): Observable<any> {
        return this.userDataSource.verifyForgotCode(code);
    }

    changePassword( code: string, password: string, repeat: string): Observable<any> {
        return this.userDataSource.changePassword(code, password, repeat);
    }

    createUser( user: User, birthday, aniv, pass: string, conf: string, depto: number, type: number): Observable<any> {
        return this.userDataSource.createUser(user, birthday, aniv, pass, conf, depto, type);
    }

    getUser(id: number): Observable<any> {
        return this.userDataSource.getUser(id);
    }

    getUserInfo(id: number): Observable<any> {
        return this.userDataSource.getUserInfo(id);
    }

    getAllUsers(filter: string): Observable<any> {
        return this.userDataSource.getAllUsers(filter);
    }

    getUsersOfCompany(depid: number, projectid: number, filter: string, status: number): Observable<any> {
        return this.userDataSource.getUsersOfCompany(depid, projectid, filter, status);
    }

    changeAvatar(file): Observable<any> {
        return this.userDataSource.changeAvatar(file);
    }

    changeProfileInfo(user: User): Observable<any> {
        return this.userDataSource.changeProfileInfo(user);
    }

    changeProfilePassword(old, newp, repeat): Observable<any> {
        return this.userDataSource.changeProfilePassword(old, newp, repeat);
    }

    getProfileCommitmentsStats(days, fechade, fechahasta): Observable<any> {
        let fechahastain = new Date();
        fechahastain.setHours(23,59,59,0);
        let fechadein = new Date();
        fechadein.setHours(0,0,0,0);
        if(days == -1 ){
            fechadein = fechade;
            fechahastain = fechahasta;
        } else {
            if(days > 30) {
                fechadein = new Date(fechadein.getFullYear(), 0, 1);
                fechadein.setHours(0,0,0,0);
            } else {
                fechadein.setDate(fechadein.getDate() - days);
            }
        }
        return this.userDataSource.getProfileCommitmentsStats(fechadein, fechahastain);
    }

    getUserCommitmentsStats(user_id, days, fechade, fechahasta, userBy, proy): Observable<any> {
        let fechahastain = new Date();
        fechahastain.setHours(23,59,59,0);
        let fechadein = new Date();
        fechadein.setHours(0,0,0,0);
        if(days == -1 ){
            fechadein = fechade;
            fechahastain = fechahasta;
        } else {
            if(days > 30) {
                fechadein = new Date(fechadein.getFullYear(), 0, 1);
                fechadein.setHours(0,0,0,0);
            } else {
                if(days != 1) fechadein.setDate(fechadein.getDate() - days);
            }
        }
        return this.userDataSource.getUserCommitmentStats(user_id, fechadein, fechahastain, userBy, proy);
    }

    updateUser(user: User, birthday, aniv, pass: string, conf: string, type: number): Observable<any> {
        return this.userDataSource.updateUser(user, birthday, aniv, pass, conf, type);
    }

    deactivateUser(user_id: number ): Observable<any> {
        return this.userDataSource.deactivateUser(user_id);
    }

    tagUserIntoComment(user_id: number[], com_id: number) :Observable<any> {
        return this.userDataSource.tagUserIntoComment(user_id, com_id);
    }

    getUserSubscriptions(): Observable<any> {
        return this.userDataSource.getUserSubscriptions();
    }

    subscribeToTag(tag_id: number): Observable<any> {
        return this.userDataSource.subscribeToTag(tag_id);
    }

    unsuscribeFromTag(tag_id: number): Observable<any> {
        return this.userDataSource.unsuscribeFromTag(tag_id);
    }

    private twoDigits(num) {
        if(num < 10) return '0'+num;
        else return num;
    }

    getUserTaggedCommitments(filter: string): Observable<any> {
        let edate = new Date();
        let idate = new Date();
        idate.setMonth(idate.getMonth() - 3);
        let ini_date = idate.getFullYear() + '-' + this.twoDigits(idate.getMonth()+1) + '-' + this.twoDigits(idate.getDate());
        let end_date = edate.getFullYear() + '-' + this.twoDigits(edate.getMonth()+1) + '-' + this.twoDigits(edate.getDate());
        return this.userDataSource.getUserTaggedCommitments(ini_date, end_date, filter);
    }

    getBirthdays(): Observable<any> {
        return this.userDataSource.getBirthdays();
    }

    getHomeWeekStats(): Observable<any> {
        return this.userDataSource.getHomeWeekStats();
    }

    getHomeMonthStats(): Observable<any> {
        return this.userDataSource.getHomeMonthStats();
    }

    setAsPaymentMaker(user_id: number): Observable<any> {
        return this.userDataSource.setAsPaymentMaker(user_id);
    }

    removePaymentMaker(user_id: number): Observable<any> {
        return this.userDataSource.removePaymentMaker(user_id);
    }

    getPaymentMakers():Observable<any> {
        return this.userDataSource.getPaymentMakers();
    }
}
