import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PartnerDataSource } from "../datasources/partner.datasource";
import { PartnerData } from "../models/partnerdata";

@Injectable()
export class PartnerRepository {
    constructor(private _datasource: PartnerDataSource) {}

    getPartnerData(): Observable<any> {
        return this._datasource.getPartnerData();
    }

    updatePartnerDate(data: PartnerData): Observable<any> {
        return this._datasource.updatePartnerDate(data);
    }

    getPartnerCode(): Observable<any> {
        return this._datasource.getPartnerCode();
    }
}