import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DailyReportDataSource } from "../datasources/dailyreport.datasource";

@Injectable()
export class DailyReportRepository {
    constructor(
        private datasource: DailyReportDataSource
    ) {}

    private twoDigits(num) {
        if(num < 10) return '0' + num;
        else return num; 
    }

    getSentReports(year: number, month: number, day: number, people): Observable<any> {
        return this.datasource.getSentReports(year, month+1, day, people);
    }

    getReceivedReports(year: number, month: number, day: number, people): Observable<any> {
        return this.datasource.getReceivedReports(year, month+1, day, people);
    }

    sendReport(texto: string, fecha_user: Date, user_id): Observable<any> {
        let datestr = fecha_user.getFullYear() + '-' + this.twoDigits(fecha_user.getMonth()+1) + '-' + this.twoDigits(fecha_user.getDate());
        datestr += ' ' + this.twoDigits(fecha_user.getHours()) + ':' + this.twoDigits(fecha_user.getMinutes()) + ':00';
        let us_id = []
        for (const us of user_id) {
            us_id.push(us.id);
        }
        return this.datasource.sendReport(texto, datestr, us_id);
    }

    addFileToReport(report_id: number, file): Observable<any> {
        return this.datasource.addFileToReport(report_id, file);
    }

    getReportFiles(report_id): Observable<any> {
        return this.datasource.getReportFiles(report_id);
    }

    setReportAsSeen(report_id): Observable<any> {
        return this.datasource.setReportAsSeen(report_id);
    }
}