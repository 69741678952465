import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './auth/request.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CompanyRepository } from './repositories/company.repository';
import { CompanyDataSource } from './datasources/company.datasource';
import { UserRepository } from './repositories/user.repository';
import { UserDataSource } from './datasources/user.datasource';
import { DepartmentRepository } from './repositories/department.repository';
import { DepartmentDataSource } from './datasources/department.datasource';
import { CommitmentDataSource } from './datasources/commitment.datasource';
import { CommitmentRepository } from './repositories/commitment.repository';
import { ProjectRepository } from './repositories/project.repository';
import { ProjectDataSource } from './datasources/project.datasource';
import { DragService } from './directives/drag.service';
import { MacrotaskDataSource } from './datasources/macrotask.datasource';
import { MacrotaskRepository } from './repositories/macrotask.repository';
import { TagRepository } from './repositories/tag.repository';
import { TagDataSource } from './datasources/tag.datasource';
import { MeetingRepository } from './repositories/meeting.repository';
import { MeetingDataSource } from './datasources/meeting.datasource';
import { OutdepartamentDataSource } from './datasources/outdepartment.datasource';
import { OutdepartmentRepository } from './repositories/outdepartment.repository';
import { FileRepository } from './repositories/file.repository';
import { FileDataSource } from './datasources/files.datasource';
import { DailyReportDataSource } from './datasources/dailyreport.datasource';
import { DailyReportRepository } from './repositories/dailyreport.repository';
import { NotificationsRepository } from './repositories/notifications.repository';
import { NotificationsDataSource } from './datasources/notifications.datasource';
import { CalendarRepository } from './repositories/calendar.repository';
import { CalendarDataSource } from './datasources/calendar.datasource';
import { TickerRepository } from './repositories/ticker.repository';
import { TickerDataSource } from './datasources/ticker.datasource';
import { Regex } from './models/regex';
import { ChatRepository } from './repositories/chat.repository';
import { ChatDataSource } from './datasources/chat.datasource';
import { DispersionRepository } from './repositories/dispersion.repository';
import { DispersionDataSource } from './datasources/dispersion.datasource';
import { SupportRepository } from './repositories/support.repository';
import { SupportDataSource } from './datasources/support.datasource';
import { PartnerRepository } from './repositories/partner.repository';
import { PartnerDataSource } from './datasources/partner.datasource';
import { ClockCheckerRepository } from './repositories/clock-checker.repository';
import { ClockCheckerDataSource } from './datasources/clock-checker.datasource';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    CompanyRepository,
    CompanyDataSource,
    UserRepository,
    UserDataSource,
    DepartmentRepository,
    DepartmentDataSource,
    CommitmentRepository,
    CommitmentDataSource,
    ProjectRepository,
    ProjectDataSource,
    MacrotaskDataSource,
    MacrotaskRepository,
    TagRepository,
    TagDataSource,
    MeetingRepository,
    MeetingDataSource,
    OutdepartmentRepository,
    OutdepartamentDataSource,
    FileRepository,
    FileDataSource,
    DailyReportRepository,
    DailyReportDataSource,
    NotificationsRepository,
    NotificationsDataSource,
    CalendarRepository,
    CalendarDataSource,
    TickerRepository,
    TickerDataSource,
    ChatRepository,
    ChatDataSource,
    SupportRepository,
    SupportDataSource,
    PartnerRepository,
    PartnerDataSource,
	  DispersionRepository,
	  DispersionDataSource,
    DragService,
    Regex,
    ClockCheckerRepository,
    ClockCheckerDataSource,
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
