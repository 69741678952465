import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClockCheckerDataSource {
    constructor(
        private http: HttpClient
    ) {}

    private twoDigits(d) {
        if(d < 10) return '0'+d;
        else return d;
    }

    setCheckIn(checkin_time: String): Observable<any> {
        let checkIn = new Date();
        let time_zone = checkIn.toString().substr(25,8);
        let stringcheckin = checkIn.getFullYear()+'-'+this.twoDigits(checkIn.getMonth()+1)+'-'+this.twoDigits(checkIn.getDate());
        stringcheckin += ' '+this.twoDigits(checkIn.getHours())+':'+this.twoDigits(checkIn.getMinutes())+':'+this.twoDigits(checkIn.getSeconds());
        let today = new Date();
        let stringtoday = today.getFullYear()+'-'+this.twoDigits(today.getMonth()+1)+'-'+this.twoDigits(today.getDate());
        stringtoday += ' 00:00:00';
        let stringcheckin_time = today.getFullYear()+'-'+this.twoDigits(today.getMonth()+1)+'-'+this.twoDigits(today.getDate());
        stringcheckin_time += ' '+checkin_time.substr(0,2)+'-'+checkin_time.substr(3,2);
        let url = `${environment.apiUrl}/check/create?fecha_in=${stringcheckin}&time_zone=${time_zone}&checkin_time=${stringcheckin_time}&today=${stringtoday}`;
        return this.http.get<any>(url);
    }

    setCheckOut(): Observable<any> {
        let checkOut = new Date();
        let stringcheckout = checkOut.getFullYear()+'-'+this.twoDigits(checkOut.getMonth()+1)+'-'+this.twoDigits(checkOut.getDate());
        stringcheckout += ' '+this.twoDigits(checkOut.getHours())+':'+this.twoDigits(checkOut.getMinutes())+':'+this.twoDigits(checkOut.getSeconds());
        let today = new Date();
        let stringtoday = today.getFullYear()+'-'+this.twoDigits(today.getMonth()+1)+'-'+this.twoDigits(today.getDate());
        stringtoday += ' 00:00:00';
        let url = `${environment.apiUrl}/check/update?fecha_out=${stringcheckout}&today=${stringtoday}`;
        return this.http.get<any>(url);
    }

    getChecks(filtrado: number, user_to: number, id_departamento: number,fecha_de: Date, fecha_hasta: Date): Observable<any> {
        let stringde = fecha_de.getFullYear()+'-'+this.twoDigits(fecha_de.getMonth()+1)+'-'+this.twoDigits(fecha_de.getDate());
        stringde += ' '+this.twoDigits(fecha_de.getHours())+':'+this.twoDigits(fecha_de.getMinutes())+':'+this.twoDigits(fecha_de.getSeconds());
        let stringhasta = fecha_hasta.getFullYear()+'-'+this.twoDigits(fecha_hasta.getMonth()+1)+'-'+this.twoDigits(fecha_hasta.getDate());
        stringhasta += ' '+this.twoDigits(fecha_hasta.getHours())+':'+this.twoDigits(fecha_hasta.getMinutes())+':'+this.twoDigits(fecha_hasta.getSeconds());
        let url = `${environment.apiUrl}/check?fecha_de=${stringde}&fecha_hasta=${stringhasta}`;
        if(filtrado == 1) {
            url += '&filtrado='+filtrado;
            if(user_to != -1) url += '&user_to='+user_to;
            if(id_departamento != -1) url += '&id_departamento='+id_departamento;
        }
        return this.http.get<any>(url).pipe(map(response => {return response;}));
    }
}