import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ClockCheckerDataSource } from "../datasources/clock-checker.datasource";

@Injectable()
export class ClockCheckerRepository {
    constructor(
        private clockCheckerDataSource : ClockCheckerDataSource
    ){}

    setCheckIn(checkin_time): Observable<any> {
        return this.clockCheckerDataSource.setCheckIn(checkin_time);
    }

    setCheckOut(): Observable<any> {
        return this.clockCheckerDataSource.setCheckOut();
    }

    getChecks(days, filtrado, user_to, id_departamento): Observable<any> {
        let fechahastain = new Date();
        fechahastain.setHours(23,59,59,0);
        let fechadein = new Date();
        fechadein.setHours(0,0,0,0);
        if(days > 30){
            fechadein = new Date(fechadein.getFullYear(), 0, 1);
            fechadein.setHours(0,0,0,0);
        } else {
            if(days != 1) fechadein.setDate(fechadein.getDate() - days);
        }
        return this.clockCheckerDataSource.getChecks(filtrado, user_to, id_departamento, fechadein, fechahastain);
    }
}