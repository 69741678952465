import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class CalendarDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getCalendar(ini_date: string, end_date: string, id_proyecto: number, id_macro: number, id_depto: number):Observable<any> {
        let url = `${environment.apiUrl}/calendar?ini_date=${ini_date}&end_date=${end_date}`;
        if(id_proyecto != -1) url += `&id_proyecto=${id_proyecto}`;
        if(id_macro != -1) url += `&id_macrotarea=${id_macro}`;
        if(id_depto != -1) url += `&id_depto=${id_depto}`;
        return this.http.get<any>(url).pipe(map(response=>{return response;}));
    }
}