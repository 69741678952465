import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class SupportDataSource {
    constructor(private _http: HttpClient) {}

    getSupportMessages(): Observable<any> {
        return this._http.get<any>(`${environment.apiUrl}/mensaje/atencion`).pipe(map(response=>{return response;}));
    }

    readAsSupport(ids: number[]): Observable<any> {
        return this._http.put<any>(`${environment.apiUrl}/mensaje/atencion`, {
            'mensaje_id[]': ids
        }).pipe(map(response=>{return response;}));
    }

    answerSupport(msg: string, user_id: number): Observable<any> {
        return this._http.post<any>(`${environment.apiUrl}/mensaje/atencion`, {
            mensaje: msg,
            user_id: user_id
        }).pipe(map(response=>{return response;}));
    }

    getMessagesFrom(user_from: number): Observable<any> {
        return this._http.get<any>(`${environment.apiUrl}/mensajes/atencion/user?user_from=${user_from}`).pipe(map(response=>{return response;}));
    }
}