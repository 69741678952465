import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChatDataSource } from "../datasources/chat.datasource";

@Injectable()
export class ChatRepository {
    constructor(private _datasource: ChatDataSource) {}

    getResume():Observable<any> {
        return this._datasource.getResume();
    }

    sendIndividualMessage(msg: string, user_id: number):Observable<any> {
        return this._datasource.sendIndividualMessage(msg, user_id);
    }

    getMessagesFrom(user_id:number):Observable<any> {
        return this._datasource.getMessagesFrom(user_id);
    }

    readMessages(ids: number[]): Observable<any> {
        return this._datasource.readMessages(ids);
    }

    getSupportMessages():Observable<any> {
        return this._datasource.getSupportMessages();
    }

    readSupportMessage(ids: number[]): Observable<any> {
        return this._datasource.readSupportMessage(ids);
    }

    sendSupportMessage(mensaje: string): Observable<any> {
        return this._datasource.sendSupportMessage(mensaje);
    }
}