import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TickerDataSource } from "../datasources/ticker.datasource";
import { Ticker } from "../models/ticker";

@Injectable()
export class TickerRepository {
    constructor(
        private datasource: TickerDataSource
    ) {}

    createNew(ticker: Ticker, depto_to: number): Observable<any> {
        return this.datasource.createNew(ticker, depto_to);
    }

    getAdminNews():Observable<any> {
        return this.datasource.getAdminNews();
    }

    deleteNew(id_ticker: number):Observable<any> {
        return this.datasource.deleteNew(id_ticker);
    }

    getNews():Observable<any> {
        return this.datasource.getNews();
    }
}