import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class ChatDataSource {
    constructor(private http: HttpClient) {}

    getResume():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/mensajes`).pipe(map(response=>{return response;}));
    }

    sendIndividualMessage(msg: string, user_id: number):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/mensajes`, {
            mensaje: msg,
            user_id: user_id,
            tipo: 'usuario',
        }).pipe(map(response=>{return response;}))
    }

    getMessagesFrom(user_id:number):Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/mensajes/user?user_from=${user_id}`).pipe(map(response=>{return response;}));
    }

    readMessages(ids: number[]): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/mensajes`, {
            'mensaje_id[]': ids
        }).pipe(map(response=>{return response;}));
    }

    getSupportMessages():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/mensaje/soporte`).pipe(map(response=>{return response;}));
    }

    readSupportMessage(ids): Observable<any> {
        let idstr = [];
        for (const item of ids) {
            idstr.push(item+'');
        }
        return this.http.put<any>(`${environment.apiUrl}/mensaje/soporte`, {
            'mensaje_id[]': idstr
        }).pipe(map(response=>{return response;}));
    }

    sendSupportMessage(mensaje: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/mensaje/soporte`,{
            mensaje: mensaje
        }).pipe(map(response=>{return response;}));
    }
}