import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class ProjectDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getProjects(filter: string, creator: number): Observable<any> {
        let url = `${environment.apiUrl}/proyectos?`
        if( filter != '') url += '&like='+filter;
        if( creator != -1) url += '&creator='+creator;
        return this.http.get<any>(url).pipe(map(response => { return response;}));
    }

    getAllProjects(filter: string, creator: number): Observable<any> {
        let url = `${environment.apiUrl}/proyectos?all=si`
        if( filter != '') url += '&like='+filter;
        if( creator != -1) url += '&creator='+creator;
        return this.http.get<any>(url).pipe(map(response => { return response;}));
    }

    addProject(name: string, color: string, file, inicio: string, fin: string): Observable<any> {
        var fd = new FormData();
        fd.append('nombre', name);
        fd.append('color', color);
        if(file != null) fd.append('photo', file);
        fd.append('fecha_inicio', inicio);
        fd.append('fecha_fin', fin);
        return this.http.post<any>(`${environment.apiUrl}/proyectos`, fd).pipe(map(response => {return response;}));
    }

    editProject(id:number, name: string, color: string, file, inicio: string, fin: string): Observable<any> {
        var fd = new FormData();
        fd.append('nombre', name);
        fd.append('color', color);
        if(file != null) fd.append('photo', file);
        fd.append('fecha_inicio', inicio);
        fd.append('fecha_fin', fin);
        return this.http.post<any>(`${environment.apiUrl}/proyecto/${id}`, fd).pipe(map(response => {return response;}));
    }

    addPersonToProject(id_proj: number, id_user: number): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/proyecto/${id_proj}`, {
            id_user: id_user
        }).pipe(map(response => {return response;}));
    }

    removePersonOfProject(id_proj: number, id_user: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/proyecto/${id_proj}?id_user=${id_user}`).pipe(map(response=>{return response;}));
    }

    getProjectInfo(id_proj: number):Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/proyecto/${id_proj}`).pipe(map(response => {return response;}));
    }

    deactivateProject(id_proj: number):Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/admin/proyecto/${id_proj}`).pipe(map(response => {return response;}));
    }

    reactivateProject(id_proj: number):Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/admin/proyecto/${id_proj}`,{}).pipe(map(response => {return response;}));
    }

    getProjectCommitmentsCount(id_proj: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/proyecto/${id_proj}/comp`).pipe(map(response=>{return response;}));
    }

    getProjectMacrotasks(id_proj: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/proyectos/${id_proj}/macrotareas`).pipe(map(response=>{return response;}));
    }

    getMenuActivities():Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/activityfeed`, {}).pipe(map(response=>{return response;}));
    }

    private twoDigits(d) {
        if(d < 10) return '0'+d;
        else return d;
    }

    getProjectCommitmentsStats(fechade: Date, fechahasta: Date, userBy: number, userC: Number, proy: number): Observable<any> {
        let stringde = fechade.getFullYear()+'-'+this.twoDigits(fechade.getMonth()+1)+'-'+this.twoDigits(fechade.getDate());
        stringde += ' '+this.twoDigits(fechade.getHours())+':'+this.twoDigits(fechade.getMinutes())+':'+this.twoDigits(fechade.getSeconds());
        let stringhasta =fechahasta.getFullYear()+'-'+this.twoDigits(fechahasta.getMonth()+1)+'-'+this.twoDigits(fechahasta.getDate());
        stringhasta += ' '+this.twoDigits(fechahasta.getHours())+':'+this.twoDigits(fechahasta.getMinutes())+':'+this.twoDigits(fechahasta.getSeconds());
        let url = `${environment.apiUrl}/reporte/proyecto?proyecto=${proy}&fechade=${stringde}&fechahasta=${stringhasta}`;
        if(userBy != -1) url += '&userto='+userBy;
        if(userC != -1) url += '&usercreator='+userC;
        return this.http.get<any>(url)
            .pipe(map(response => {return response;}));
    }
}