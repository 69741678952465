import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SupportDataSource } from "../datasources/support.datasource";

@Injectable()
export class SupportRepository {
    constructor(private _datasource: SupportDataSource) {}

    getSupportMessages():Observable<any> {
        return this._datasource.getSupportMessages();
    }
    
    readAsSupport(ids: number[]): Observable<any> {
        return this._datasource.readAsSupport(ids);
    }

    answerSupport(msg: string, user_id: number): Observable<any> {
        return this._datasource.answerSupport(msg, user_id);
    }

    getMessagesFrom(user_from: number): Observable<any> {
        return this._datasource.getMessagesFrom(user_from);
    }
}