import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company';
import { map } from 'rxjs/operators';
import { Department } from '../models/department';
import { User } from '../models/user';
import { CompanyBilling } from '../models/companyBilling';

@Injectable()
export class CompanyDataSource {
    constructor(
        private http: HttpClient
    ) {}

    createCompany(company: Company, department: Department, user: User, password, token, birth: Date, aniv: Date): Observable<any> {
        let shortname = btoa(company.nombre);
        let usuario = btoa(user.nombre_real);
        return this.http.post<any>(`${environment.apiUrl}/empresa`, 
            {
                nombre: company.nombre,
                shortname: shortname,
                limite_usuarios: company.limite_usuarios,
                codigo: company.codigo,
                depto_nombre: department.nombre,
                usuario: usuario,
                password: password,
                email: user.email,
                nombre_real: user.nombre_real,
                puesto: user.puesto,
                phone: user.phone,
                token: token, 
                dia: birth.getDate(),
                mes: (birth.getMonth()+1),
                year: birth.getFullYear(),
                dia_aniv: aniv.getDate(),
                mes_aniv: (aniv.getMonth()+1),
                year_aniv: aniv.getFullYear(),
                giro: ''
            }
        ).pipe(map(response => {
            return response;
        }));
    }

    checkCompanyNameAvailable(name: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/empresa/check?nombre=${name}`)
            .pipe(map(response => {
                return response;
            }))
    }

    getCompanyResume(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/empresa`).pipe(map(response =>{return response;}));
    }

    getStates():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/states`).pipe(map(response =>{return response;}));
    }

    getCities(state_id: number):Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/cities/${state_id}`).pipe(map(response=>{return response;}));
    }

    changeLicensesNumber(num: number):Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/empresa`, {
            licencias: num
        }).pipe(map(response=>{return response;}));
    }

    getBilling():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/empresa/facturacion`).pipe(map(response=>{return response;}));
    }

    updateBilling(bill: CompanyBilling):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/empresa/facturacion`, {
            email: bill.email,
            tel: bill.telefono,
            cp: bill.codigo_postal,
            ciudad: bill.ciudad,
            estado: bill.estado,
            col: bill.colonia,
            calle: bill.calle_y_num,
            nombre: bill.nombre,
            rfc: bill.rfc,
            no_ext: bill.no_ext,
            no_int: bill.no_int
        }).pipe(map(response=>{return response;}));
    }

    getSubscription():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/conekta/subs`).pipe(map(response=>{return response;}));
    }

    getPaymentHistory():Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/invoices`).pipe(map(response=>{return response;}));
    }

    generateSubscription(nombre: string, email: string, phone: string, token: string):Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/conekta/subs`,{
            cliente: nombre,
            email: email,
            phone: phone,
            'token[id]': token
        }).pipe(map(response=>{return response;}));
    }

    deleteSubscription():Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/conekta/subs`).pipe(map(response=>{return response;}));
    }

    payInvoice(inv_id, cliente, email, phone, token): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/invoice/${inv_id}`, {
            cliente: cliente,
            email: email,
            phone: phone,
            tipo: 'card_conekta',
            'token[id]': token
        }).pipe(map(response => {return response;}))
    }

    legalInvoice(inv_id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/invoiceBilling/${inv_id}`).pipe(map(response=>{return response;}));
    }

    updateCheckInTime(checkin_time): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/empresa/update/time`, {
            tiempo: checkin_time,
        }).pipe(map(response => {return response;}));
    }

    getCheckInTime(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/empresa/get/time`).pipe(map(response => {return response;}));
    }
}