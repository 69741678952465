import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MacrotaskDataSource } from "../datasources/macrotask.datasource";

@Injectable()
export class MacrotaskRepository {
    constructor(
        private macrotaskDatasource: MacrotaskDataSource
    ){}

    getMacrotasksOfProject(id_project): Observable<any> {
        return this.macrotaskDatasource.getMacrotasksOfProject(id_project);
    }

    private twoDigits(num) {
        if(num < 10) return '0'+num;
        else return num;
    }

    createMacrotask(nombre: string, desc: string, fecha_entrega: Date, id_project: number): Observable<any> {
        let date = fecha_entrega.getUTCFullYear()+'-'
                    +this.twoDigits(fecha_entrega.getUTCMonth()+1)+'-'
                    +this.twoDigits(fecha_entrega.getUTCDate());
        return this.macrotaskDatasource.createMacrotask(nombre, desc, date, id_project);
    }

    editMacrotask(id_macro: number, nombre: string, desc: string, fecha_entrega: Date):Observable<any> {
        let date = fecha_entrega.getUTCFullYear()+'-'
                    +this.twoDigits(fecha_entrega.getUTCMonth()+1)+'-'
                    +this.twoDigits(fecha_entrega.getUTCDate());
        return this.macrotaskDatasource.editMacrotask(id_macro, nombre, desc, date);
    }

    addUserToMacrotask(id_macro: number, id_user: number): Observable<any> {
        return this.macrotaskDatasource.addUserToMacrotask(id_macro, id_user);
    }

    removeUserOfMacrotask(id_macro: number, id_user: number): Observable<any> {
        return this.macrotaskDatasource.removeUserOfMacrotask(id_macro, id_user);
    }

    addDepartmentToMacrotask(id_macro: number, id_dep: number): Observable<any> {
        return this.macrotaskDatasource.addDepartmentToMacrotask(id_macro, id_dep);
    }

    removeDepartmentOfMacrotask(id_macro: number, id_dep: number): Observable<any> {
        return this.macrotaskDatasource.removeDepartmentOfMacrotask(id_macro, id_dep);
    }

    addTagToMacrotask(id_macro: number, id_tag: number): Observable<any> {
        return this.macrotaskDatasource.addTagToMacrotask(id_macro, id_tag);
    }

    removeTagToMacrotask(id_macro: number, id_tag: number): Observable<any> {
        return this.macrotaskDatasource.removeTagToMacrotask(id_macro, id_tag);
    }

    deleteMacrotask(id_macro: number): Observable<any> {
        return this.macrotaskDatasource.deleteMacrotask(id_macro);
    }

    getMacrotaskCommitments(id_macro: number): Observable<any> {
        return this.macrotaskDatasource.getMacrotaskCommitments(id_macro);
    }

    getMacrotaskFiles(id_macro: number): Observable<any> {
        return this.macrotaskDatasource.getMacrotaskFiles(id_macro);
    }
}