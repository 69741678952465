import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class DispersionDataSource {
    constructor(
        private http: HttpClient
    ) {}

    getDispersionUsers(filter): Observable<any> {
        let url = `${environment.apiUrl}/adminpay/users`
		let params = new HttpParams();
		params = params.append('date', filter);
        return this.http.get<any>(url,{params:params}).pipe(map(response => { return response;}));
    }

    getRewards(filter): Observable<any> {
        let url = `${environment.apiUrl}/adminpay/rewards`
		let params = new HttpParams();
		params = params.append('date', filter);
        return this.http.get<any>(url, {params:params}).pipe(map(response => { return response;}));
    }

    setPayment(id, data): Observable<any> {
        let url = `${environment.apiUrl}/adminpay/rewards/${id}`
		delete data['id_reward'];
		const formData = new FormData();
		for(var key in data){
			formData.append(key,data[key])
		}
        return this.http.post<any>(url, formData).pipe(map(response => { return response;}));
    }
}