import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CalendarDataSource } from "../datasources/calendar.datasource";

@Injectable()
export class CalendarRepository {
    constructor( private datasource: CalendarDataSource) {}

    private twoDigits(num) {
        if (num < 10 ) return '0'+num;
        else return num;
    }

    private formatDateToString(date: Date) {
        let str = date.getFullYear() + '-' + this.twoDigits(date.getMonth()+1) + '-' + this.twoDigits(date.getDate());
        return str;
    }

    getCalendar(ini_date: Date, end_date: Date, id_proyecto: number, id_macro: number, id_depto: number):Observable<any> {
        return this.datasource.getCalendar(this.formatDateToString(ini_date), this.formatDateToString(end_date), id_proyecto, id_macro, id_depto);
    }
}